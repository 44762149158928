.footer {
    width: 100%;
    
    background-color: #000;
    background: url('./images/footerBack.be437374.png') no-repeat center center/cover;
    color: #eee;
    padding: 3rem 1rem;
}






#title-Tag-Line {
  font-size: 20px;
}
/* .card-item__bg{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  position: relative;
  margin: 30px auto;
  transform: translate(0px, 50px);
  z-index: 5;
} */

/* form animation starts */
.form {
  
 background: url('./images/backgroundContactUs.png') center/cover;
 background-color: #F6ECE3;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 5px;
  text-align: center;
  
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0;
  right: 0;
  position: absolute;
  border-top: 5px solid #c9e1d4;
/*   z-index: 1; */
  animation: bounce 1.5s infinite;
}
::-webkit-input-placeholder {
  font-size: 1.3em;
}

.titleFooter{
  display: block;
  font-family: sans-serif;
  color:black;
  margin: 10px auto 5px;
  width: 80%;
}
.termsConditions{
  margin: 0 auto 5px 80px;
}

.pageTitle{
  font-size: 2em;
  font-weight: bold;
  color:black;
}
.secondaryTitle{
  color: black;
}

.name {
  background-color: #ebebeb;
  color: white;
  height: 50px;
  width: 70%;
 
}
.name:hover {
  border-bottom: 5px solid #0e3721;
  height: 60px;
  width: 90%;
  transition: ease 0.5s;
}

.email {
    
  background: #ebebeb;
  height: 2em;
    height: 50px;
      width: 70%;
}

.email:hover {
  border-bottom: 5px solid #0e3721;
  height: 60px;
    width: 90%;
  transition: ease 0.5s;
}

.message {
  background-color: #ebebeb;
  overflow: hidden;
  height: 50px;
  width: 70%;
  height: 10rem;
}

.message:hover {
  border-bottom: 5px solid #0e3721;
  height: 16em;
 
    width: 90%;
  transition: ease 0.5s;
}

.formEntry {
  display: block;
  margin: 30px auto;
  min-width: 300px;
  padding: 10px;
  border-radius: 2px;
  border: none;
  transition: all 0.5s ease 0s;
}

.submit {
  width: 200px;
  color: white;
  background-color: #0e3721;
  font-size: 20px;
}

.submit:hover {
  box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
  transform: translateY(-3px);
  width: 300px;
  border-top: 5px solid #0e3750;
  border-radius: 0%;
}

@keyframes bounce {
  0% {
    transform: translate(0, 2px);
  }
  50% {
    transform: translate(0, 16px);
  }
} 


/* .footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
} */





