.testimonials {
    width: 100%;
    background:url('./images/galleryBackground.png') repeat center
    ;
    
}


.logoIcon{
    width: 50%;
    text-align: center;
    margin: auto;
}

.testimonials h2 {
    font-size: 3rem;
    text-align: center;
}

.testimonials .container .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin: 2rem 0;
}



.testimonials img {
    border-radius: 50px;
    margin-top: 10px;
}

.img{
width: 100%;
}

.card{
    text-align: center;
    height: 40%;
    width: 100%;
}



@media screen and (max-width: 940px) {
    .testimonials .container {
        width: 100%;
    }

    .testimonials .container .content {
        grid-template-columns: 1fr;
    }

    .testimonials .card {
        max-width: 100%;
        
    }
}









