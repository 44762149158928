.navbar2 {
    text-align: center;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    
   
    

}

/* Style the links inside the navigation bar */
/* .navbar2 a {
   
    
    color: #f2f2f2;
    text-align: center;
   
    text-decoration: none;
    font-size: 17px;
} */

/* Change the color of links on hover */
/* .navbar2 Link:hover {
    background-color: #ddd;
    color: black;
} */

/* Add a color to the active/current link */
/* .navbar2 Link.active {
    background-color: #04AA6D;
    color: white;
} */

.column2 {
    float: right;
    text-align: center;
    padding: 10px;
    height: 60px;
    margin-top: 2%;
    

}

.left2 {
    width: 12.5%;
}


.right2 {
    width: 12.5%;
}
.leftBtn{
    width: 40%;
    margin-right:20px ;
}
.title2 {
    color: #E12C2C;
    font-weight: bold;


}

.middle2 {
    width: 12.5%;
    text-align: right;
}

.imgBtn{
    width: 100%;
    animation-name: order;
        animation-duration: 4s;
        animation-iteration-count: infinite;
}
.imgIcon{
    width: 110%;
    /* margin:auto; */
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}
/* 
@keyframes example {
    0% {
        
        width: 95%;
    }

    25% {
        width: 98%;
    }

    50% {
        width: 100%;
    }

    75% {
    width: 110%;
    }

    100% {
        width: 113%;
    }
} */
@keyframes order {
    0% {

        width: 100%;
    }

    25% {
        width: 105%;
    }

    50% {
        width: 108%;
    }

    75% {
        width: 110%;
    }

    100% {
        width: 112%;
    }
}

/* CSS */
.button-30 {

    text-align: center;
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 50px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}


@media screen and (max-width:720) {

    .navbar2{
        
        display: none;

    }
   
}
