.header1 {
    position: fixed;
    height: 110px;
    width: 100%;

    top: 0;
    left: 0;
    z-index: 1;
    /* transition: .3s ease-in; */
    transition: 0.25s ease transform;
    overflow: hidden;
    background-color: transparent;
    

}
.hidden{
    visibility: hidden;
}


.header1 .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    
}

.header1 .nav-menu a {
    color: black;
}

.header1 .navbar img {
    width: 40%;
    height: auto;
}

.header1 .nav-menu {
  
    display: flex;
}

.header1 .nav-item {
    padding: 1rem;
    font-weight: 500;
    border: bottom 1px;;
}

.header1 .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header1 {
        max-width: 100%;
        background-color: transparent;
    }

    .header1 .navbar {
        max-width: 100%;
    }

    .hamburger {
        
        display: block;
    }

    .nav-menu {
        position: fixed;
        right: -100%; 
        top: 90px;
        flex-direction: column;
        background-image:url('./images/back1.00f01047.png') ;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: right;
        transition: .5s;
    }

    .nav-menu.active {
        right: 0;
    }

    .nav-item  {
        
    border-bottom: 1px dotted black;
    }

    .header1 .navbar img {
        width: 40%;
    }

       
}
















