




* {
    box-sizing: border-box;
}
.menuTab{
    background-color: #F6ECE3;
   
}

.rowTab{
    height: 100%;
}
.col{
    text-align: center;
    width: 100%;
    
}
/* .imagRight{
float:right;
margin: 20x;
padding: 10px;
width: 40%;
} */
.imagLeft {
   display:inline-table;
   margin: 10px;
    padding: 10px;

    width: 40%;
    
}

.caption {
    display: block;
}


/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

