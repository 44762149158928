* {
    box-sizing: border-box;
}

/* Position the image container (needed to position the left and right arrows) */
.container {
    position: relative;
    height: 0%;
}

/* Hide the images by default */
.mySlides {
    display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
    cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    margin-top:40px;
    color: black;
    font-weight: bold;
    font-size: 25px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
   margin-right: 87%;
    
    border-radius: 3px 0 0 3px;
}
.orderBtn{
    width: 40%;
    cursor: pointer;
}
.orderBtn:hover{
    width: 50%;

}
.next{
    background-color: #ff9d00;
        
}

.videoPlayerClose{
    width: 100%;
    position: absolute;
    left: 49%;
    top: 34.6%;
    transform: translate(-50%,-50%);
    display: none;
   
}
.videoPlayerOpen {
width: 100%;
    position: absolute;
    left: 50%;
    top: 60.0%;
    transform: translate(-50%, -50%);
    
}

video:focus{
    outline: none;
    display: none;
    
}
.close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    cursor: pointer;

}
.small-img-btn{
    width: 10%;
    position: absolute;
    transform: translate(240%, 250%);
    cursor: pointer;
    
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: #ff9d00;;
}

.play-btn{
    width: 100px;
    position: absolute;
    left:50%;
    top: 39%;
    transform: translate(-50%,100%);
    cursor: pointer;
}
/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Container for image text */
.caption-container {
    text-align: center;
    background-color: #ff9d00;
    padding: 5px 16px;
    font-size: xx-large;
    color: rgb(255, 254, 254);
}

.rowGallery:after {
    content: "";
    display: table;
    clear: both;
}

/* Six columns side by side */
.columnGallery {
    float: left;
    width: 33.33%;
}

/* Add a transparency effect for thumnbail images */
.demo {
    opacity: 0.9;
}

.active,
.demo:hover {
    opacity: 1;
}