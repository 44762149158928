/* .about {
     background: url('./images/Photo\ Collage\ Beach\ Summer\ Vibes\ Instagram\ Story.png') no-repeat center center/cover;
    background-size: cover; 
     margin: auto;
    padding: 4rem 1rem 0 1rem;  
} */
.imageBackground{
    width: 100%;
    height: 100%;
    
}

.about .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about h2 {
    font-size: 3rem;
}

.about .col-2 {

    color:azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}

/* .about .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
} */

.about button {
    margin-top: 1rem;
}


@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 90vw;
    }

    .about .container .col-2 {
        padding: 0;
    }

    .about button {
        /* margin-bottom: 1rem; */
    }
}









