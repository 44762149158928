* {
    box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.column {
    float: left;
    padding: 10px;
    height: 90px;
    border-bottom: 1mm ridge rgba(211, 220, 50, .6);
   
}


.left{
    width: 15%;
    margin: auto;
}
.right {
    width: 25%;
}

.title{
    color:#E12C2C;
    font-weight: bold;
    font-size:large;
    
    
}
.bottleImg{
    
                
 margin: auto;
height: 100%;
width: 60%;     
}
.glassImg {
    margin: auto;
    height: 100%;
    width: 60%;
}

.description{
    color:black;
    font-size:medium;

}
.middle {
    width: 60%;
    text-align: right;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}




/* 
@media screen and (min-width:900) {

    .column{
        height:400px;
    }
} */