.hero {
    
    color: #fff;
    
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('./images/back1.00f01047.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero .content {
    /* height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    color: black;
    padding: 1rem; */
    position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
    
}
/* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    /* background-color: #000000cc; 
} */


.hero .content p:first-child {
    font-style: italic;
    font-size: 1.5rem;
    
   
  
}

.hero .content p:nth-child(2) {
    font-size: 1.9rem;
   
}

.hero .content p:nth-child(3) {
    font-size: 5rem;
    font-weight: 700;
    
}


.hero .content p:nth-child(4) {
    font-size: 2.2rem;
    font-style: italic;
  
}





.grid-container {
    
    display: grid;
    margin:auto;
    grid-template-columns: auto auto auto ;
    
    
   
    
}



.grid-item {
   
    
  
  
    text-align: center;
    
}
.grid-item img {

    width:50% 
}
.grid-container.grid-item p:first-child {
    font-style: italic;
    font-size: 1.5rem;



}


/* .hero .button {
    margin: 1rem 0;
    width: 300px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #333;
    
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
} */






/* CSS */
.button-42 {
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 50%;
    z-index: 9;
    
    border: 0;
    transition: box-shadow .2s;
    
}

.button-42:hover {
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
}



.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch; 
}

.grid img {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    
}




 .section.hero .typography {
     padding-right: 1em;
 }

 .section.hero .gallery {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 1.75vw;
 }

 /* .btnDown {
     text-align: center;
     animation-name: example;
     animation-duration: 4s;
     animation-iteration-count: infinite;
 } */
 .btnDown {
     animation: MoveUpDown 2s linear infinite;
     position: relative;
     text-align: center;
     margin-top: 20%;
     bottom: 0;
 }

 @keyframes MoveUpDown {

     0%,
     100% {
         bottom: 0;
     }

     50% {
         bottom: 50px;
     }
 }

 /* @keyframes example {
     0% {

         padding-top: 10px;
     }

     25% {
        margin-bottom: 10px;
     }

     50% {
        margin-bottom: 10px;
     }

     75% {
        margin-bottom: 10px;
     }

     100% {
        margin-bottom: 10px;
     }
 } */




 
 /* .section.hero .gallery .custom-image {
     border-radius: 3px;
     overflow: hidden;
     transform: scale(0);
     opacity: 0;
     animation: getAnimationDuration(.5s) popOut forwards;
 }
 
 .section.hero .gallery .custom-image:nth-child(2) {
     animation-delay: .1s;
 }
 
 .section.hero .gallery .custom-image:nth-child(3) {
     animation-delay: .2s;
 }
 
 .section.hero .gallery .custom-image:nth-child(6) {
     animation-delay: .3s;
 }
 
 .section.hero .gallery .custom-image:nth-child(9) {
     animation-delay: .4s;
 }
 
 .section.hero .gallery .custom-image:nth-child(8) {
     animation-delay: .5s;
 }
 
 .section.hero .gallery .custom-image:nth-child(7) {
     animation-delay: .6s;
 }
 
 .section.hero .gallery .custom-image:nth-child(4) {
     animation-delay: .7s;
 }
 
 .section.hero .gallery .custom-image:nth-child(5) {
     animation-delay: .8s;
} */

@media screen and (max-width:940px) {
.hero {

        color: #fff;
        height: 100vh;
        width: 100%;
        position:relative;
    }
    .hero .content {
        text-align: center;
    }
    .hero .content p:nth-child(3) {
        font-size: 2.6rem;
    }

    .hero .content p:nth-child(4) {
        font-size: 1.6rem;
    }
}